.kalevski.container {
    height: 80vh;
    margin: 10vh auto;

    header {
        max-height: 150px;
        margin: 50px 0;
        
    }

    .row.content {
        height: calc(100% - 150px);
    }
}

@include media-breakpoint-down(lg) { 
    .kalevski.container {
        height: auto;
        margin: 0 auto;

        header {
            padding: 10px 0;
            max-height: none;
        }

        .row.content {
            height: auto;
        }
    }
}

