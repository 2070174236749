.component.project-box {
    height: calc(100% - 70px);
    margin-top: 70px;
    position: relative;
    display: block;
    padding: 20px 25px;

    &::before, &:after {
        position:absolute;
        border-color:#e91e63;
        border-style:dashed;
        content: ' ';
        pointer-events: none;
    }

    &::before { // left top
        width:120px; height: 180px;
        top:0;
        left:0;
        border-width: 6px 0 0 6px;
    }

    &::after { // bottom right
        width:160px; height: 80px;
        bottom:0;
        right:0;
        border-width: 0 6px 6px 0;
    }

    h1 {
        text-decoration: underline;
        letter-spacing: -5px;
    }

    ul {

        list-style-type: square;
        font-size: 36px;

        a:link, a:active, a:visited {
            text-decoration: none;
        }

        li {
            color:#e91e63;
            
            h2 {
                font-size: 24px;
                color: rgba($color: #fff, $alpha: 0.6);
                text-decoration: underline;
                display: inline-block;
                padding-right: 10px;
            }

            span {
                background:rgba($color: #e91e63, $alpha: 0.4);
                font-size: 14px;
                line-height: 14px;
                padding: 5px;
                margin: 0 5px;
                border-radius: 5px;
                display: inline-block;
            }

            p {
                font-size: 18px;
                letter-spacing: -1px;
                margin: 0;
                padding: 0;
                line-height: 18px;
            }
        }
    }
}