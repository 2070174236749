@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import "../../node_modules/bootstrap/scss/bootstrap-reboot.scss";
@import "../../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "../../node_modules/bootstrap/scss/functions";

* {
    font-family: 'Share Tech Mono', monospace;
    color: #fff;

    letter-spacing: 0px;
}

body {
    background-color: #1d1d20;
    background-image: url('../images/grid_bg.png');
    background-repeat: repeat;
    background-size: initial;
    background-position: 0 13px;
}

p {
    margin: 5px 0;
}

a, a:link, a:visited, a:active {
    color: #e91e63;
}

@import "./main.container";

@import "./logo";
@import "./welcome_text";
@import "./projects";