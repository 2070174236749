.welcome-text {

    // background: rgba($color: #fff, $alpha: 0.2);
    padding: 100px 40px;

    p {
        margin: 0;
    }

    p:nth-child(1) {
        font-size: 36px;
    }

    p:nth-child(2) {
        font-size: 48px;
    }

    p:nth-child(3) {
        font-size: 18px;
    }

    a {
        display: block;
        margin: 20px 0;
        font-size: 24px;
    }

    .social-links > * {
        display: inline-block;
        margin: 0 10px;
    }
}

