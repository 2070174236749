.component.logo {
    
    display: flex;
    align-items:center;

    img {
        max-height: 55px;
    }

    h1 {
        font-size: 60px;
        margin: 0;
        padding-left: 5px;
        letter-spacing: -5px;

        span {
            color: #e91e63;
            letter-spacing: -5px;
            font-size: 48px;
        }
    }

}